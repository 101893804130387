<template>
  <div class="detail_content">
    <TopBar :type="2" :pageTitle="'文本课程详情'" />

    <div class="nav_header">
      <div class="nav_box">
        <!-- <div class="leftMenu flex" >
          
          <p>{{ contentInfo.title }}</p>
        </div> -->
        <div class="rightBtn" >
          <ul class="flex">
            <li @click="menuState = !menuState">{{menuState?'收起目录':'展开目录'}}</li>
            <li v-if="detailInfo.prev_page&&detailInfo.prev_page.id" @click="prevPage(detailInfo.prev_page.id)">上一节</li>
            <li v-if="detailInfo.next_page&&detailInfo.next_page.id" @click="nextPage(detailInfo.next_page.id)">下一节</li>
            <li v-if="detailInfo.content&&detailInfo.content.min_view_limit" class="limit-time">{{limitTime}}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="menu_list" v-if="menuState">
        <ul>
          <li
            v-for="item in chapterList"
            :key="item.id"
            @click="changeMenu(item.id)"
            :class="text_course_chapter_id==item.id?'active':''"
          >
            {{ item.title }}
          </li>
        </ul>
      </div>
      <div class="right_cont">
        <!-- {{}} -->
        <p v-html="contentInfo.content"></p>
        <div class="maodian" ref="maodian"></div>
      </div>
    </div>
  </div>
</template>
<script>
import TopBar from '@/m/common/topBar.vue'

export default {
  name: "detail",
  components: {
    TopBar
  },
  data() {
    return {
      limitTime: '',    //倒计时
      over: false,      //阅读结束
      timerOver: false, //倒计时结束
      detailId: "",
      tabIndex: "1",
      detailInfo: {},
      text_course_chapter_id: "",
      text_course_id: "",
      contentInfo: "",
      chapterList: [],
      menuState:false,
      timer:'',//计时器
      
    };
  },
  mounted() {
    this.text_course_chapter_id = this.$route.query.id;
    this.text_course_id = this.$route.query.text_course_id;
    // if (this.detailId) {
    this.scrollChange();
    this.getDetail();
    // }
  },
  methods: {
    getDetail() {
      clearInterval(this.timer);
      this.menuState = false
      let parmas = {
        text_course_id: this.text_course_id,
        text_course_chapter_id: this.text_course_chapter_id,
      };
      this.$axios
        .post(`/v1/textCourse/play`, parmas, { useLog: true })
        .then((res) => {

          if (res.code == 1) {
            this.detailInfo = res.data;
            this.contentInfo = res.data.content;
            this.chapterList = res.data.text_course_chapter;
            
            if (res.data.content.min_view_limit > 0) {
              this.limitTime = res.data.content.min_view_limit + ":00";
              this.countdown(res.data.content.min_view_limit);
            } else {
              this.getRecord();
            }
          }
        });
    },
    //倒计时
    countdown(date) {
      let _this = this;
      let maxtime = date * 60; //一个小时，按秒计算，自己调整!

      this.timer = setInterval(function () {
        if (maxtime >= 0) {
          let minutes = Math.floor(maxtime / 60);
          let seconds = Math.floor(maxtime % 60);
          let msg = minutes + ":" + (seconds != 0 ? (seconds<10?'0'+seconds:seconds) : '00');
          _this.limitTime = msg;
          --maxtime;
        } else {
          _this.timerOver = true;
          _this.getRecord();
          clearInterval(_this.timer);
        }
      }, 1000);
    },
    //监听滚动
    scrollChange () {
      window.addEventListener('scroll', this.handleScroll)
    },
    //判断锚点是否出现在可视区
    handleScroll(){
      
      var exp_height = this.$refs.maodian.offsetTop;
      var clientheight = document.documentElement.clientHeight;
      // var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      var scrollTop = window.pageYOffset ;
      if(clientheight+scrollTop > exp_height) {
        this.over = true;
        //  this.getRecord();
      }
    },
    changeMenu(id) {
      this.text_course_chapter_id = id;
      this.getDetail();
    },
    prevPage(id){
      this.text_course_chapter_id = id;
      this.getDetail();
    },
    nextPage(id){
      this.text_course_chapter_id = id;
      this.getDetail();
    },
    getRecord() {
      let parmas = {
        text_course_id: this.text_course_id,
        text_course_chapter_id: this.text_course_chapter_id,
      };
      this.$axios
        .post(`/v1/textCourse/readRecord`, parmas, { useLog: true })
        .then((res) => {});
    },
  },
  watch: {
    //监听表单
    over(newName){
      if(newName && this.timerOver){
        this.getRecord();
      }
    },
    // timerOver(newName){
    //   if(newName && this.over){
    //     this.getRecord();
    //   }
    // },
  }
};
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
  align-items: center;
}
.detail_content {
  background: #f6f6f6;
  // width: 100%;
  // margin: 0 30px;
  .nav_header {
    width: 100%;
    height: 70px;
    background: #fff;
    .nav_box {
      // width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 70px;
      .leftMenu {
        img {
          width: 24px;
          height: 24px;
          display: block;
        }
        p {
          font-size: 16px;
          margin-left: 10px;
        }
      }
      .rightBtn {
        width: 100%;
        li {
          width: 104px;
          height: 40px;
          border-radius: 4px;
          border: 1px solid #ccc;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          font-weight: 400;
          color: #666;
          margin-left: 20px;
          
          &.limit-time{
            border: none;
            margin-left: auto;
          }
        }
      }
    }
  }
  .container {
    // width: 1200px;
    // background: #fff;
    border-radius: 10px;
    margin: 0px auto 0;
    padding: 10px 0px;
    display: flex;
    div.menu_list {
      position: fixed;
      left: 0;
      top: 160px;
      width: 100%;
      min-height: calc(100vh - 160px);
      height: auto;
      background: rgba(0,0,0,0.5);
      
      
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      li {
        box-sizing: border-box;
        padding: 10px 30px;
        line-height: 70px;
        font-size: 16px;
        cursor: pointer;
        background: #fff;
      }
      li:hover {
        color: #3ca7fa;
      }
      li.active {
        color: #3ca7fa;
      }
    }
    div.right_cont {
      background: #fff;
      margin:0 20px;
      flex: 1;
      padding: 20px;
      font-size: 16px;
    }
  }
}
</style>